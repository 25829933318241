import React from "react";
import Form from "./Form";
import Sidebar from "./Sidebar";
import { Route, Routes, Outlet } from "react-router-dom";

// function Dashboard() {
//   return (
//     <div>
//       <Sidebar/>
//       <Routes>
//         <Route path="/" element={<div/>} />
//         <Route path="/form" element={<Form />} />
//       </Routes>
//       <Outlet/>
//     </div>
//   )
// }

// export default Dashboard

function Dashboard() {
  return (
    <div >  
      
      <Sidebar/>

     
        <Outlet /> 
  
    </div>
  );
}

export default Dashboard;


import axios from "axios"
 const host= "https://api.purplekinko.com"
 //const host ="http://localhost:7070"
// let headers = {
//     "X-Access-Token": sessionStorage.getItem("token"),
//     headers: { Authorization: `Bearer ${token}` }
//   };
  const token = sessionStorage.getItem("token")
export const postForm =(data)=>{
  const token = sessionStorage.getItem("token")
  console.log(token)
return axios 
.post(host+"/api/admin/addService",data,{
    headers: { Authorization: `Bearer ${token}` }
  }
)
.then((res)=>{
    console.log(res)
    return res ;
})
}

export const loginApi =(data)=>{
    return axios 
    .post(host+"/auth/login",data)
    .then((res)=>{
        return res;
    })
}

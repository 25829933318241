import logo from "./logo.svg";
import "./App.css";
import Login from "./loginPage/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Sidebar from "./pages/Sidebar";
import Form from "./pages/Form";
import Dashboard from "./pages/dashboard";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/horuss" element={<Dashboard />}>
          <Route path="addService" element={<Form />} />
          </Route>
          <Route path="/horus" element={<Form />}>
            {/* <Route path="addService" element={<Form />} /> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

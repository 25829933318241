import React from 'react'
import { GrServices } from "react-icons/gr";

function Sidebar() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark">
          <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
            <div className="d-flex mt-5 bg-white text-dark w-100 rounded">
              <img src="https://dyimg77.exportersindia.com/company_logo/9832288.jpeg" className="logo px-3" alt="logo" />
              <span className="fs-5 d-none d-sm-inline pt-1">Horus Digital</span>
            </div>
            <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start py-2" id="menu">
              <li className="nav-item">
                <span className="fs-5">Services</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar

import React, { useState } from 'react'
import "../loginPage/Login.css"
import {Link,useNavigate} from "react-router-dom"
import {loginApi} from "../ApiServices"

function Login() {
  const navigate = useNavigate();
  const [login,setlogin]=useState({
    // email :"gopi@decoders.com",
    // password:"Admin@123"
    email :"",
    password:""
  })

  const handleSubmit=(e)=>{
  e.preventDefault()
  

  loginApi(login)
  .then((res)=>{
     sessionStorage.setItem("token",res.data.token)
    console.log(res.data)
      navigate("/horus/")
  }).catch((err)=>{
    console.log(err)
  })
  }
  
  const handleChange=(e)=>{
    e.preventDefault()
    // setlogin(
    //   [e.target.name] = e.target.value
    // )
    setlogin((login)=>({
      ...login,
      [e.target.name]:e.target.value
    }))
    console.log(login , "am login response ")
  }

  return (
    <form onSubmit={handleSubmit}>
    <div>
      <div className='border-bottom mt-3 text-center d-flex px-5 py-2'>
        <img src="https://dyimg77.exportersindia.com/company_logo/9832288.jpeg" className='logo'></img>
       <h2 className='text-start px-2'>Horus Digital</h2>
      </div>
<div className='center'>
  <div class="mb-3 col-sm-5"> 
    <label for="email" class="form-label fw-medium">Email address</label>
    <input
      type="email"
      class="form-control inputfield"
      id="email"
      name="email"
      placeholder="horusdigimarketing@gmail.com"
      value={login.email}
      onChange={handleChange}
      required
    />
  </div>
  <div class="mb-3 col-sm-5"> 
    <label for="password" class="form-label fw-medium">Password</label>
    <input
      class="form-control inputfield"
      type="password"
      id="password"
      name="password"
      rows="3"
      value={login.password}
      onChange={handleChange}
      required
    />
  </div>
  <div>
    <button type='submit' className='btn btn-primary px-4 py-2 my-2'>Submit</button>
  </div>
</div>

    </div>
    </form>
  )
}

export default Login

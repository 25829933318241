import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { postForm } from "../../src/ApiServices";
import { MenuItem, OutlinedInput, Select } from "@mui/material";
import dropdownDataStates from "../dropdownDatas.json";
import FileBase64 from "react-file-base64";
import { useState } from "react";
function MyForm() {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    designation: Yup.string().required("Designation is required"),
    website: Yup.string().required("Website is required"),
    contactPersonName: Yup.string().required("Contact PersonName is required"),
    category: Yup.string().required("Category is required"),
    emailId: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phoneNo: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .min(10, "Phone number must be at least 10 digits")
      .max(15, "Phone number must not exceed 15 digits")
      .required("Phone number is required"),
    address: Yup.string().required("Address is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    area: Yup.string().required("Area is required"),
    servicesOffering: Yup.string().required("Service is required"),
    frontImage: Yup.string().required("File is required"),
    BackImage: Yup.string().required("File is required"),
  });
  const names = [
    "SEO",
    "Digital Marketing",
    "Web Development",
    "App Development",
    "Content Writing",
    "Search engine optimization",
    "Search engine marketing",
    "Content marketing",
    "Social media marketing",
    "Pay-per-click",
    "Email marketing",
    "Conversion rate optimization",
    "Marketing automation",
    "Analytics",
    "Return on investment",
    "Brand awareness",
    "Influencer marketing",
    "Video marketing",
    "Mobile marketing",
  ];

  const category = ["category1", "category2", "category3", "category4"];
  const Dropdowncategories = [
    "Technology",
    "Healthcare",
    "Retail",
    "Finance",
    "Hospitality",
    "Real Estate",
    "Education",
    "Manufacturing",
    "Entertainment",
    "Professional Services",
  ];
  const handleFile = (file) => {
    setFile(file);
  };
  const [file, setFile] = useState(null);
  console.log(file, "am the fileeeeee");

  return (
    <div>
      <Formik
        initialValues={{
          name: "",
          category: "",
          phoneNo: "",
          contactPersonName: "",
          designation: "",
          website: "",
          emailId: "",
          address: "",
          stars: "",
          state: "",
          city: "",
          area: "",
          servicesOffering: [],
          frontImage: "",
          BackImage: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          postForm(values)
            .then((res) => {
              console.log(res);
              // Reset form after successful submission
              resetForm();

              setSubmitting(false);
            })
            .catch((err) => {
              alert(err);
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className="mx-4">
            <div className="form">
              <div className="form-group col-md-6">
                <label htmlFor="name">Company Name</label>
                <Field
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-danger"
                />
              </div>
              {/* <div className="form-group col-md-6">
                <label htmlFor="description">Description</label>
                <Field
                  type="text"
                  className="form-control"
                  id="description"
                  name="description"
                />
                <ErrorMessage name="description" component="div" />
              </div> */}
              <div className="form-group col-md-6">
                <label htmlFor="category">Category</label>
                <Field
                  type="text"
                  className="form-control"
                  id="category"
                  name="category"
                />
                {/* <Select
                  labelId="category"
                  id="category"
                  // multiple
                  value={values.category}
                  onChange={(event) => {
                    const selected = event.target.value;
                    setFieldValue("category", event.target.value);
                  }}
                  className="form-control"
                  input={<OutlinedInput label="Name" />}
                >
                  {Dropdowncategories.map((categories) => (
                    <MenuItem key={categories} value={categories}>
                      {categories}
                    </MenuItem>
                  ))}
                </Select> */}
                <ErrorMessage
                  name="category"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="servicesOffering">Services Offering</label>
                {/* <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={values.servicesOffering}
          onChange={(event) => {
            const selected = event.target.value;
            setFieldValue("servicesOffering", [
              ...values.servicesOffering,
              ...selected.filter(item => !values.servicesOffering.includes(item))
            ]);
          }}
          className="form-control"
          input={<OutlinedInput label="Name" />}
        >
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name}
            >
              {name}
            </MenuItem>
          ))}
        </Select> */}
                <Field
                  type="text"
                  className="form-control"
                  id="servicesOffering"
                  name="servicesOffering"
                />
                <ErrorMessage
                  name="servicesOffering"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="contactPersonName">Contact Person Name</label>
                <Field
                  type="text"
                  className="form-control"
                  id="contactPersonName"
                  name="contactPersonName"
                />
                <ErrorMessage
                  name="contactPersonName"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="designation">Designation</label>
                <Field
                  type="text"
                  className="form-control"
                  id="designation"
                  name="designation"
                />
                <ErrorMessage
                  name="designation"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="emailId">Email Id</label>
                <Field
                  type="email"
                  className="form-control"
                  id="emailId"
                  name="emailId"
                />
                <ErrorMessage
                  name="emailId"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="phoneNo">Phone Number</label>
                <Field
                  type="tel"
                  className="form-control"
                  id="phoneNo"
                  name="phoneNo"
                />
                <ErrorMessage
                  name="phoneNo"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="website">Website</label>
                <Field
                  type="text"
                  className="form-control"
                  id="website"
                  name="website"
                />
                <ErrorMessage
                  name="website"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="address">Address</label>
                <Field
                  type="text"
                  className="form-control"
                  id="address"
                  name="address"
                />
                <ErrorMessage
                  name="address"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="state">State</label>
                {/* <Field
                  type="text"
                  className="form-control"
                  id="state"
                  name="state"
                /> */}
                <Select
                  labelId="demo-name-label"
                  id="demo-name"
                  value={values.state}
                  onChange={(event) => {
                    const selected = event.target.value;
                    setFieldValue("state", selected);
                  }}
                  className="form-control"
                  input={<OutlinedInput label="Name" />}
                >
                  {dropdownDataStates.states.map((item) => (
                    <MenuItem key={item.name} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <ErrorMessage
                  name="state"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="city">City</label>
                {/* <Field
                  type="text"
                  className="form-control"
                  id="city"
                  name="city"
                /> */}
                <Select
                  labelId="demo-name-label"
                  id="demo-name"
                  value={values.city}
                  onChange={(event) => {
                    const selected = event.target.value;
                    setFieldValue("city", selected);
                  }}
                  className="form-control"
                  input={<OutlinedInput label="Name" />}
                >
                  {dropdownDataStates.states
                    .filter((item) => item.name === values.state)
                    .map((states) =>
                      states.cities.map((item) => (
                        <MenuItem key={item.name} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))
                    )}
                </Select>
                <ErrorMessage
                  name="city"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="area">Area</label>
                {/* <Field
                  type="text"
                  className="form-control"
                  id="area"
                  name="area"
                /> */}
                <Select
                  labelId="demo-name-label"
                  id="demo-name"
                  value={values.area}
                  onChange={(event) => {
                    const selected = event.target.value;
                    setFieldValue("area", selected);
                  }}
                  className="form-control"
                  input={<OutlinedInput label="Name" />}
                >
                  {dropdownDataStates.states
                    .filter((item) => item.name === values.state)
                    .map((states) =>
                      states.cities
                        .filter((city) => city.name === values.city)
                        .map((city) =>
                          city.areas.map((item) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))
                        )
                    )}
                </Select>
                <ErrorMessage
                  name="area"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="frontImage">Upload Front Side Pic</label>
                <FileBase64
                  multiple={false}
                  onDone={(file) => setFieldValue("frontImage", file.base64)}
                />
                <ErrorMessage
                  name="frontImage"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="BackImage">Upload Back Side Pic</label>
                <FileBase64
                  multiple={false}
                  onDone={(file) => setFieldValue("BackImage", file.base64)}
                />
                <ErrorMessage
                  name="BackImage"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default MyForm;
